<template>
<div>

	<div class="text-center mt-5 " v-if="cargando">
		<div class="spinner-border text-primary spinner-border-sm" role="status">
			<span class="sr-only">Loading...</span>
		</div>
		<br>
		Cargando ...
	</div>

	<div v-if="!cargando">

			<div class="row mb-2">
					<div class="col-md-12">
						
			<form v-on:submit.prevent="GuardarInformacion">
			
						<div class="mb-2">
							<h4 class="m-0"> Nuevo Personal  </h4>
							Por favor complete el siguiente formulario, los campos con <span class="text-danger">*</span> son necesarios.
						</div>

						<div class="card  card-body shadow-sm mb-2">
							<strong>Información Laboral</strong>
							<hr>


							<div class="row mb-2">
								<label for="cargos_idcargos" class="col-md-2 col-form-label">Cargo:</label>
								<div class="col-md-5">
								<input type="text" class="form-control" id="cargos_idcargos" name="cargos_idcargos"  value="">
								</div>
							</div>


							<div class="row mb-2">
								<label for="empresasede_idempresasede" class="col-md-2 col-form-label">Sede:</label>
								<div class="col-md-9">
								<input type="text" class="form-control" id="empresasede_idempresasede" name="empresasede_idempresasede"  value="">
								</div>
							</div>

							<div class="row mb-2">
								<label for="numerocontrato" class="col-md-2 col-form-label">Número Contrato:</label>
								<div class="col-md-2">
								<input type="text" class="form-control" id="numerocontrato" name="numerocontrato"  value="">
								</div>
							</div>

							<div class="row mb-2">
								<label for="fechacontrato" class="col-md-2 col-form-label">Fecha de Contrato:</label>
								<div class="col-md-5">
								<input type="date" class="form-control" id="fechacontrato" name="fechacontrato"  value="">
								</div>
							</div>

							<div class="row mb-2">
								<label for="finalizacontrato" class="col-md-2 col-form-label">Fecha Terminación:</label>
								<div class="col-md-5">
								<input type="date" class="form-control" id="finalizacontrato" name="finalizacontrato"  value="">
								</div>
							</div>

							<div class="row mb-2">
								<label for="descripcioncontrato" class="col-md-2 col-form-label">Objeto Contrato:</label>
								<div class="col-md-9">
									<textarea   class="form-control" id="descripcioncontrato" name="descripcioncontrato"  cols="15" rows="5"></textarea>
								</div>
							</div>

							<div class="row mb-2">
								<label for="salario" class="col-md-2 col-form-label">Salario:</label>
								<div class="col-md-4">
									<input type="text" class="form-control" id="salario" name="salario" v-model="contrato.salario">
								</div>
								<div class="col-md-4">
									$ {{ Intl.NumberFormat("es-CO").format(contrato.salario) }}  COP
								</div>
							</div>

							<div class="row mb-2">
								<label for="tipovinculacion" class="col-md-2 col-form-label">Tipo Vinculación:</label>
								<div class="col-md-9">
									<select  class="form-control" id="tipovinculacion" name="tipovinculacion" >
										<option value="1">Contrato a Término Fijo</option>
										<option value="2">Contrato a Término indefinido</option>
										<option value="3">Contrato civil por prestación de servicios</option>
										<option value="4">Contrato de Obra o labor</option>
										<option value="5">Contrato temporal, ocasional o accidental  </option>
										<option value="6">Contrato de aprendizaje </option>
									</select>
								</div>
							</div>

						
						</div>

						<div class="card card-body mb-2">
							
								<strong>Datos Personales</strong>
								<hr>

								<div class="row mb-2">
										<label class="col-md-2 col-form-label" for="identificacion">Identificación ID: <span class="text-danger">*</span> </label>
										<div class="col-sm-3">
											<input type="text" required class="form-control" id="identificacion" v-model="datospersona.identificacion">
										</div>
								</div>
							
								<div class="row mb-2">
										<label class="col-md-2 col-form-label" for="identificacion">Tipo Identificación: </label>
										<div class="col-md-9">
										<select required id="tipoidentificacion" class="form-control" v-model="datospersona.tipoidentificacion">
											<option value="">-</option>
											<option value="1"> CC - Cédula de ciudadanía </option>
											<option value="2"> CE - Cédula de extranjería </option>
											<option value="3"> PA - Pasaporte </option>
											<option value="4"> PET - Permiso Especial de Trabajo </option>
										</select>
										</div>
								</div>


								<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="lugarexpedicion">Fecha Expedición:</label>
									<div class="col-sm-5">
									<input type="date" class="form-control" id="fechaexpedicion" v-model="datospersona.fechaexpedicion">
									</div>
								</div>

								<div class="row mb-2">
										<label class="col-md-2 col-form-label" for="lugarexpedicion">Lugar Expedición: <span class="text-danger">*</span> </label>
									<div class="col-sm-5">
										<select required class="form-control" id="lugarexpedicion"  v-model="datospersona.lugarexpedicion">
											<option>Seleccione</option>
											<option v-for="item in ciudades" :value="item.idciudad" :key="item.idciudades">
												{{item.nombreciudad}} / {{item.nombredepartamento}} </option>
										</select>
									</div>
								</div>


								<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="nombres"> Nombre(s): <span class="text-danger">*</span></label>
									<div class="col-md-9">
									<input required type="text" class="form-control" id="nombres" v-model="datospersona.nombres">
									</div>
								</div>
								<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="apellidos"> Apellidos: <span class="text-danger">*</span></label>
									<div class="col-md-9">
									<input required type="text" class="form-control" id="apellidos" v-model="datospersona.apellidos">
									</div>
								</div>

							<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="fechanacimiento">Fecha Nacimiento:</label>
									<div class="col-sm-5">
										<input type="date" class="form-control" id="fechanacimiento" v-model="datospersona.fechanacimiento">
									</div>
								</div>
		
							<div class="row mb-2">
								<label class="col-md-2 col-form-label" for="lugarnacimiento">Lugar Nacimiento: <span class="text-danger">*</span> </label>
								<div class="col-sm-5">
								<select required class="form-control" id="lugarnacimiento" v-model="datospersona.lugarnacimiento">
									<option value="">-</option>
									<option v-for="item in ciudades.filter(ciudades => ciudades.ciudad_idciudad !== null)" :value="item.idciudad" :key="item.idciudades">
										{{item.nombreciudad}} / {{item.nombredepartamento}} </option>
								</select>
								</div>
							</div>
							

							<div class="row mb-2">
								<label class="col-md-2 col-form-label" for="genero">Sexo:</label>
								<div class="col-sm-5">
									<div class="form-check ">
										<input class="form-check-input" type="radio" name="genero" id="hombre"  value="H" v-model="datospersona.genero"> 
										<label   class="form-check-label" for="hombre"> Hombre </label>
									</div>
									<div class="form-check">
										<input class="form-check-input" type="radio" name="genero"  id="mujer" value="M" v-model="datospersona.genero">
										<label  class="form-check-label" for="mujer"> Mujer </label>
									</div>
								</div>
							</div>

						</div>
						<div class="card card-body mb-2">

							<strong>Datos de Contacto</strong>
							<hr>

								<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="telefono">Teléfono/Celular:</label>
									<div class="col-sm-4">
										<input type="number" min="0" minlength="6" maxlength="10" class="form-control" id="telefono" v-model="datospersona.telefono">
									</div>
									<div class="col-sm-4">
										<input type="number" min="0" minlength="6" maxlength="10" class="form-control" id="celular" v-model.number="datospersona.celular">
									</div>
								</div>

								
								<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="email">Email: <span class="text-danger">*</span> </label>
									<div class="col-md-9">
										<input type="email" required class="form-control" id="email" v-model="datospersona.email">
									</div>
								</div>

								<div class="row mb-2">
									<label class="col-md-2 col-form-label">Dirección:</label>
									<div class="col-md-9">
										<input type="text" class="form-control" id="direccion" v-model="datospersona.direccion">
									</div>
								</div>

								<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="barrio">Barrio:</label>
									<div class="col-md-9">
										<input type="text" class="form-control" id="barrio" v-model="datospersona.barrio">
									</div>
								</div>

								<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="ciudad_idciudad">Ciudad: <span class="text-danger">*</span> </label>
									<div class="col-sm-5">
									<select required class="form-control" id="ciudad_idciudad" v-model="datospersona.ciudad_idciudad">
										<option value="">-</option>
										<option v-for="item in ciudades.filter(ciudades => ciudades.ciudad_idciudad !== null)" :value="item.idciudad" :key="item.idciudades"> {{item.nombreciudad}} / {{item.nombredepartamento}} </option>
									</select>
									</div>					
								</div>					


							<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="estrato">Estrato:</label>
									<div class="col-sm-2">
										<select class="form-control" id="estrato" v-model="datospersona.estrato">
											<option value="">-</option>
											<option value="1">1</option>
											<option value="2">2</option>
											<option value="3">3</option>
											<option value="4">4</option>
											<option value="5">5</option>
											<option value="6">6</option>
										</select>
									</div>
							</div>

						</div>
						<div class="card card-body mb-2">

								<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="estadocivil">Estado Civil:</label>
									<div class="col-md-9">
										<select class="form-control" id="estadocivil" v-model="datospersona.estadocivil">
											<option value="">-</option>
											<option value="0">Soltero/a</option>
											<option value="1">Casado/a</option>
											<option value="2">En Relación ( más de 1 Año de noviazgo)</option>
											<option value="3">Comprometido/a</option>
											<option value="4">Unión libre o unión de hecho</option>
											<option value="5">Separado/a</option>
											<option value="6">Divorciado/a</option>
											<option value="7">Viudo/a</option>
										</select>
									</div>
								</div>

								<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="tiposangre">Tipo de Sangre:</label>
									<div class="col-sm-2">
										<select class="form-control" id="tiposangre" v-model="datospersona.tiposangre">
											<option value="">-</option>
											<option value="O+">O+</option>
											<option value="O-">O-</option>
											<option value="A+">A+</option>
											<option value="A-">A-</option>
											<option value="B+">B+</option>
											<option value="B-">B-</option>
											<option value="AB+">AB+</option>
											<option value="AB-">AB-</option>
										</select>
									</div>
								</div>

							<div class="row mb-2">
									<label class="col-md-2 col-form-label" for="composicionfamiliar">Composición Familiar:</label>
									<div class="col-md-9">
										<input type="text" class="form-control" id="composicionfamiliar" v-model="datospersona.composicionfamiliar">
									</div>
							</div>


							<div class="row mt-3">
									<label class="col-md-2 col-form-label" for="Observación:">Observación:</label>
									<div class="col-md-9">
										<textarea class="form-control" id="observacion" v-model="datospersona.observacion" rows="5"></textarea>
									</div>
							</div>

							<div class="row  mt-3">
									<div class="col-sm-3">
									</div>

									<div class="col-md-9">

										<button :disabled="deshabilitar" class="btn btn-primary btn-sm mr-2" type="submit">
										<span v-show="!deshabilitar"> <i class="fas fa-save mr-2"></i> Guardar Información </span>
										<span v-show="deshabilitar"> <div class="spinner-border spinner-border-sm mr-2" role="status"> </div> Guardando Información </span> </button>

										<a @click="$router.go(-1)" class="btn btn-sm btn-light"> Cancelar </a>
									</div>

							</div>

							<input type="hidden" id="tipopersona" v-model="datospersona.tipopersona">
							<input type="hidden" id="idpersonas" v-model="datospersona.idpersonas">
							
						</div>
			</form>

					</div>
				</div>
	</div>
</div>
	
</template>

<script>

	import axios from 'axios'
	import router from '../../router'

	export default {

		data() {
			return {
				contrato: {
						salario: 0
				},
				datospersona: {
					idpersonas: '',
					personascategoria_idpersonascategoria: '',
					tipopersona: '',
					identificacion: '',
					tipoidentificacion: '',
					lugarexpedicion: '',
					fechaexpedicion: '',
					nombres: '',
					apellidos: '',
					fechanacimiento: '',
					lugarnacimiento: '',
					genero: '',
					telefono: '',
					celular: '',
					direccion: '',
					barrio: '',
					ciudad_idciudad: '',
					estrato: '',
					email: '',
					estadocivil: '',
					tiposangre: '',
					autorizacionfirma: '',
					firma: '',
					recepcion: '',
					composicionfamiliar: '',
					observacion: '',
					password: '',
				},
				ciudades: [],
				deshabilitar: false,
				cargando: true
			}
		},
		methods: {
			
			ConsultarCiudades: function () {
				axios.get('/api/ciudades').then(response => {
					this.ciudades = response.data;
					this.cargando = false;
				});
			},
			GuardarInformacion: function()
			{
				this.deshabilitar=true;

				axios.post('/rest/agregarpersona',{ data: this.datospersona },  { headers: { Authorization: "Bearer " + localStorage.getItem("token") } } )
				.then((response)=>{
						this.$router.push({ name: 'informamcionpersonal', params: { id: response.data  } })
				}).catch(function (error) {


				});
				},
				validaridentificacion() {
					const url = `/rest/validaridentificacion/${this.identificacion}`
					axios.get(url).then(response => { this.validar = response.data });
					return this.validar;
			}
		},
		mounted() {

			this.ConsultarCiudades();

		}
	}
</script>
 